import classNames from 'classnames'
import React from 'react'
import {useStyles} from '@wix/tpa-settings/react'
import {LIST_ALIGNMENT} from '@wix/wix-events-commons-statics'
import {MainListTitle} from '../../main-list-title'
import {LoadMoreButton} from '../../load-more-button'
import {getEvents} from '../../../selectors/events'
import {
  getCardBorderWidth,
  getCardMargins,
  getComponentConfig,
  getListDividerWidth,
  getListStripWidth,
  getSideBySideLayoutMargins,
  isCardsLayout,
  isListLayout,
} from '../../../selectors/settings'
import {useWidgetState} from '../../../hooks/state-provider'
import {ListItemContainer} from '../../event-container/list-item-container'
import {CardContainer} from '../../event-container/card-container'
import {isMainTitleVisible} from '../../../selectors/list-settings'
import {getListAlignmentClassName} from '../../../selectors/classes'
import stylesParams from '../../../../stylesParams'
import {isMobile} from '../../../../../../commons/selectors/environment'
import {CardsMobileItem} from './cards-mobile-item'
import s from './cards-mobile.scss'

export const CardsMobile = () => {
  const events = useWidgetState(getEvents)
  const mobile = useWidgetState(isMobile)
  const componentConfig = useWidgetState(getComponentConfig)
  const styles = useStyles()
  const alignment = mobile ? styles.get(stylesParams.listAlignment) : LIST_ALIGNMENT.LEFT
  const isDesktopListLayout = isListLayout(componentConfig)
  const isDesktopCardsLayout = isCardsLayout(componentConfig)
  const dividerWidth = isDesktopListLayout ? getListDividerWidth(componentConfig) : getCardBorderWidth(componentConfig)

  const getStripWidth = () => {
    if (isDesktopCardsLayout) {
      return getCardMargins(componentConfig, true)
    } else if (isDesktopListLayout) {
      return getListStripWidth(componentConfig, mobile, true)
    } else {
      return getSideBySideLayoutMargins(componentConfig, true)
    }
  }

  const stripWidth = getStripWidth()
  const mainTitleVisible = useWidgetState(isMainTitleVisible)

  const className = classNames(s.container, isDesktopListLayout ? s.listContainer : s.cardContainer, {
    [s.noTitle]: !mainTitleVisible,
    [s.noBottomPadding]: isDesktopListLayout,
    [s[getListAlignmentClassName(alignment)]]: true,
    [s.narrow]: !mobile,
  })

  const Container = isDesktopListLayout ? ListItemContainer : CardContainer

  return (
    <div className={className} data-hook="ev-list-layout">
      <MainListTitle />
      <ul>
        {events.map((event: ExtendedEvent, index: number) => (
          <Container
            key={event.id}
            event={event}
            lastItem={events.length - 1 === index}
            mobile
            dividerWidth={dividerWidth}
            stripWidth={stripWidth}
          >
            {hovered => <CardsMobileItem event={event} hovered={hovered} />}
          </Container>
        ))}
      </ul>
      <LoadMoreButton />
    </div>
  )
}
